
import ViewModel from '@/models/ViewModel'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Api from '@/models/Api'
import moment from 'moment'
import IconAction from '../IconAction/IconAction.vue'

@Component({
  components: {
    IconAction,
  },
})
export default class NotificationItem extends ViewModel {
  @Prop()
  public notification!: any

  @Prop()
  public action!: any

  @Prop()
  public icon!: any

  @Prop({ default: 'success' })
  public color!: any

  public get DOWNLOAD_ACTION() {
    if (!this.action) return false
    return this.action.type === 'download'
  }

  public get expired_action() {
    if (!this.remainingTime) return false
    return this.remainingTime.hours < 0 && this.remainingTime.minutes < 0
  }

  public fileIcon(name) {
    let icon = ''
    if (name.includes('.pdf')) icon = 'las la-file-pdf'

    if (name.includes('.xls') || name.includes('.xlsx') || name.includes('.csv')) {
      icon = 'las la-file-excel'
    }

    if (name.includes('.zip') || name.includes('.rar')) {
      icon = 'las la-file-archive'
    }

    if (name.includes('.doc') || name.includes('.docx')) {
      icon = 'las la-file-word'
    }

    return icon
  }

  public download() {
    const api = new Api()
    api.download(
      `export-download?target=${encodeURIComponent(this.notification.data.download_name)}`,
      this.notification.data.output_name,
    )
  }

  public get remainingTime() {
    if (!this.notification || this.notification.sub_type != 'file_download') return false

    const createdAtMoment = moment(this.notification.created_at)

    const endTimeMoment = createdAtMoment.clone().add(1, 'day')

    const currentTimeMoment = moment()

    const duration = moment.duration(endTimeMoment.diff(currentTimeMoment))

    const remainingHours = Math.floor(duration.asHours())

    const remainingMinutes = duration.minutes()

    return {
      hours: remainingHours,
      minutes: remainingMinutes,
    }
  }
}
