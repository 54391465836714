var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',{class:{
    sidebar: true,
    sidebarStatic: _vm.layout.sidebarStatic,
    sidebarOpened: !_vm.layout.sidebarClose,
    'd-print-none': 'd-print-none',
  },on:{"mouseenter":_vm.sidebarMouseEnter,"mouseleave":() => {
      _vm.sidebarMouseLeave()
      _vm.show_input = false
      _vm.search_for = ''
      _vm.removeAllListeners()
    }}},[_c('header',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"/app/insights"}},[(_vm.user.company_logo)?_c('span',[(!_vm.layout.sidebarClose || _vm.layout.sidebarStatic)?_c('img',{attrs:{"src":_vm.cdnPath + 'images/logo/' + _vm.user.company_logo_white,"width":"165"}}):_c('img',{attrs:{"src":_vm.cdnPath + 'images/logo/' + _vm.user.company_icon,"width":"40"}})]):_c('span',[(!_vm.layout.sidebarClose || _vm.layout.sidebarStatic)?_c('img',{attrs:{"src":"/images/castiron_media.logo.white.svg","width":"165"}}):_c('img',{attrs:{"src":"/images/castiron_media.icon.white.svg ","width":"40"}})])])],1),_c('div',{staticClass:"d-flex pl-3 pr-4 pt-4"},[(!_vm.layout.sidebarClose || _vm.layout.sidebarStatic)?_c('FormInput',{ref:"search_input",staticClass:"hide-label",attrs:{"vertical":"","placeholder":"Search"},model:{value:(_vm.search_for),callback:function ($$v) {_vm.search_for=$$v},expression:"search_for"}}):_vm._e()],1),_c('ul',{ref:"nav_list",staticClass:"nav pt-0",attrs:{"tabindex":"-1"}},[(_vm.user.isSuperAdmin && _vm.user.department && _vm.user.department !== 'ad_ops')?_c('NavLink',{attrs:{"header":"Dashboard","link":"/app/legacy-dashboard","icon":"stream","index":"legacy-dashboard"}}):_vm._e(),_vm._l((_vm.enabledMenus),function(link){return _c('NavLink',{key:link.index,attrs:{"header":link.title,"link":link.link,"icon":link.icon,"index":link.index,"is-header":link.isHeader,"children-links":link.children,"label":link.label}})})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }