<template>
  <li v-if="!childrenLinks && isHeader" :class="{ headerLink: true, className }">
    <router-link :to="linkPath">
      <span v-if="icon" class="icon">
        <i :class="`la la-${icon}`" />
      </span>
      {{ header }} <sup v-if="label" class="headerLabel">{{ label }}</sup>
      <b-badge v-if="badge" class="badge rounded-f" variant="warning" pill>
        {{ badge }}
      </b-badge>
    </router-link>
  </li>
  <li v-else-if="childrenLinks" :class="{ headerLink: true, className }">
    <div>
      <a :class="{ 'router-link-active': isActive, 'd-flex': true }" @click="toggleChildren">
        <span v-if="icon" class="icon">
          <i :class="`la la-${icon}`" />
        </span>
        {{ header }} <sup v-if="label" class="headerLabel">{{ label }}</sup>
        <div :class="{ caretWrapper: true }">
          <i :class="`la la-angle-${showChildren ? 'down' : 'left'}`" />
        </div>
      </a>
    </div>
    <b-collapse :id="'collapse' + index" :visible="showChildren">
      <ul>
        <NavLink
          v-for="link in childrenLinks"
          :key="link.link"
          :active-item="activeItem"
          :header="link.title"
          :index="link.index"
          :link="link.link"
          :icon="link.icon"
          :children-links="link.childrenLinks"
          :label="link.label"
          @navigate="toggleChildren"
        />
      </ul>
    </b-collapse>
  </li>
  <li v-else :class="{ headerLink: true, className }">
    <router-link :to="linkPath" :class="{ 'router-link-active': isActive }">
      <span v-if="icon" class="icon">
        <i :class="`la la-${icon}`" />
      </span>
      {{ header }}
      <sup v-if="label" class="headerLabel">{{ label }}</sup>
    </router-link>
  </li>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'NavLink',
  props: {
    badge: { type: String, default: '' },
    header: { type: String, default: '' },
    icon: { type: String, default: '' },
    headerLink: { type: String, default: '' },
    link: { default: '' },
    childrenLinks: { type: Array, default: null },
    className: { type: String, default: '' },
    isHeader: { type: Boolean, default: false },
    deep: { type: Number, default: 0 },
    activeItem: { type: String, default: '' },
    label: { type: String, default: '' },
    index: { type: String, default: '' },
  },
  data() {
    return {
      headerLinkWasClicked: true,
      showChildren: false,
    }
  },
  computed: {
    isActive() {
      return (
        this.$route.meta.index === this.index
        || (!this.showChildren
          && this.childrenLinks
          && this.childrenLinks.some(l => l.index === this.$route.meta.index))
      )
    },
    linkPath() {
      if (typeof this.link === 'string') {
        return this.link
      }
      return this.link()
    },
  },
  mounted() {
    this.showChildren = (this.childrenLinks && this.childrenLinks.some(l => l.index === this.$route.meta.index))
      || localStorage.getItem(`showChildren:${this.index}`) === 'true'
  },
  methods: {
    ...mapActions('layout', ['changeSidebarActive']),
    toggleChildren() {
      this.showChildren = !this.showChildren
      localStorage.setItem(`showChildren:${this.index}`, this.showChildren.toString())
    },
    togglePanelCollapse(link) {
      this.changeSidebarActive(link)
      this.headerLinkWasClicked = !this.headerLinkWasClicked || !this.activeItem.includes(this.index)
    },
  },
}
</script>

<style src="./NavLink.scss" lang="scss" scoped />
