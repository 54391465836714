
import { Component, Prop, Vue } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import moment from 'moment'

@Component
export default class NotifyMfa extends ViewModel {
  public modal: boolean = false

  public toggleModal(val: any = null) {
    if (val === null) {
      this.modal = !this.modal
      return
    }
    this.modal = val
  }

  public activateMFA() {
    this.$router.push({ name: 'Account', query: { target: 'mfa' } })
    this.modal = false
  }

  public remindLater() {
    let remind_at = moment(new Date()).endOf('day')
    let formattedDate = remind_at.format('M/D/Y HH:mm:ss')
    localStorage.setItem('mfa-remind-me', formattedDate)
    this.modal = false
  }
}
