
import { Component, Ref } from 'vue-property-decorator'
import isScreen from '@/core/screenHelper'
import NavLink from './NavLink/NavLink.vue'
import ViewModel from '../../models/ViewModel'
import menu from './menu'
import FormInput from '../FormInput/FormInput.vue'

@Component({
  components: {
    NavLink,
    FormInput,
  },
})
export default class Sidebar extends ViewModel {
  @Ref() nav_list!: HTMLBaseElement

  @Ref() search_input!: HTMLBaseElement

  public menu: any = []

  public sidebar_active: boolean = false

  public alerts: any = []

  public search_for: string = ''

  public show_input: boolean = false

  public setActiveByRoute() {
    const paths = this.$route.fullPath.split('/')
    paths.pop()
    this.layout.changeSidebarActive(paths.join('/'))
  }

  public sidebarMouseEnter() {
    if (!this.layout.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
      this.layout.switchSidebar(false)
      this.setActiveByRoute()
    }
  }

  public sidebarMouseLeave() {
    if (!this.layout.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
      this.layout.switchSidebar(true)
      this.layout.changeSidebarActive(null)
    }
  }

  public created() {
    this.setActiveByRoute()
  }

  public get isManagementUser() {
    const ids: string[] = [
      '924cbfaf-79f7-4e7d-b5cb-493a6e2e2790', // TS
      '8e3e41ea-e22a-4f6f-8e34-a557b2fcae57', // BH
      '8e3e41ea-927d-4a19-be60-39c856c5b212', // VL
    ]
    return ids.includes(this.user.id)
  }

  public get enabledMenus() {
    let menus = menu
      .map(item => {
        if (item.children !== undefined) {
          item.children = item.children.filter(
            child => child.acl === undefined || this.user.can(child.acl.model, child.acl.action),
          )
        }
        return item
      })
      .filter(
        (link: any) =>
          (link.children === undefined
            && (!link.acl || this.user.can(link.acl.model, link.acl.action)))
          || (link.children !== undefined
            && link.children.some((c: any) => !c.acl || this.user.can(c.acl.model, c.acl.action))),
      )
    if (this.search_for !== '') {
      return menus.filter((menu: any) => (
        menu.title.toLowerCase().includes(this.search_for.toLowerCase())
          || (menu.children
            && menu.children.some((child: any) =>
              child.title.toLowerCase().includes(this.search_for.toLowerCase())))
      ))
    }
    return menus
  }

  public removeAllListeners() {
    window.removeEventListener('keydown', () => {})
    window.removeEventListener('click', () => {})
    this.nav_list.removeEventListener('focus', () => {})
  }

  // remove listener when component is destroyed
  public beforeDestroy() {
    this.removeAllListeners()
  }

  public mounted() {
    // if this nav_list is focused, then we can listen to cntl shift f to open search_input
    this.nav_list.addEventListener('focus', () => {
      window.addEventListener('keydown', e => {
        if (e.ctrlKey && e.shiftKey && e.key === 'F') {
          this.show_input = true
        }
      })
    })
  }
}
