var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'theme-helper': true, 'theme-helper-opened': _vm.opened }},[_c('section',{staticClass:"widget theme-helper-content"},[_c('header',{staticClass:"theme-helper-header d-flex p-0"},[_c('div',{staticClass:"theme-helper-toggler",on:{"click":_vm.toggle}},[_vm._m(0)]),_c('h6',[_vm._v("Theme")])]),_c('div',{staticClass:"widget-body mt-3"},[_c('div',{staticClass:"theme-switcher"},[_c('div',{staticClass:"theme mb-3"},[_c('input',{attrs:{"id":"css-light","type":"radio","name":"theme-variant","aria-label":"Sing Light"},domProps:{"checked":_vm.dashboardTheme === _vm.dashboardThemes.LIGHT,"value":_vm.dashboardThemes.LIGHT},on:{"click":function($event){return _vm.changeTheme(_vm.dashboardThemes.LIGHT)}}}),_vm._m(1)]),_c('div',{staticClass:"theme mb-3"},[_c('input',{attrs:{"id":"css-dark","type":"radio","name":"theme-variant","aria-label":"Sing Dark"},domProps:{"checked":_vm.dashboardTheme === _vm.dashboardThemes.DARK,"value":_vm.dashboardThemes.DARK},on:{"click":function($event){return _vm.changeTheme(_vm.dashboardThemes.DARK)}}}),_vm._m(2)])]),_vm._m(3),_vm._m(4),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"theme-helper-spinner bg-warning text-white"},[_c('i',{staticClass:"la la-cog"}),_c('i',{staticClass:"la la-cog fs-smaller"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"css-light"}},[_c('img',{staticClass:"theme-image",attrs:{"src":require("../../assets/theme-light.png"),"alt":"light theme"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"css-dark"}},[_c('img',{staticClass:"theme-image",attrs:{"src":require("../../assets/theme-dark.png"),"alt":"dark theme"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('a',{staticClass:"btn btn-warning btn-rounded-f btn-block fs-mini text-white",attrs:{"href":"https://flatlogic.com/admin-dashboards/sing-app-vue","target":"_blank","role":"button"}},[_vm._v(" Purchase ")]),_c('a',{staticClass:"btn btn-inverse btn-rounded-f btn-block fs-mini",attrs:{"href":"http://demo.flatlogic.com/sing-app/documentation/","target":"_blank","role":"button"}},[_vm._v(" Documentation ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between mt-lg"},[_c('a',{staticClass:"btn btn-outline-default btn-rounded-f fs-mini text-muted d-flex align-items-center",attrs:{"role":"button","target":"_blank","href":"https://flatlogic.com/contact"}},[_c('i',{staticClass:"glyphicon glyphicon-headphones mr-xs"}),_vm._v(" Support ")]),_c('a',{staticClass:"btn btn-outline-default btn-rounded-f fs-mini text-muted d-flex align-items-center",attrs:{"role":"button","target":"_blank","href":"https://github.com/flatlogic/sing-app"}},[_c('i',{staticClass:"fa fa-github mr-xs"}),_vm._v(" Github ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-lg d-flex flex-column align-items-center theme-sharing"},[_c('span',{staticClass:"fs-sm"},[_vm._v(" Thank you for sharing! ")]),_c('div',{staticClass:"d-flexjustify-content-center text-light mt-2"},[_c('a',{attrs:{"target":"_blank","href":"https://twitter.com/intent/tweet?text=Admin%20dashboard%20template%20built%20with%20NodeJS,%20React%20and%20Bootstrap%204!&url=https://demo.flatlogic.com/sing-app/html-bs4/&via=flatlogic"}},[_c('i',{staticClass:"fa fa-twitter pr-1"})]),_c('a',{attrs:{"href":"https://www.facebook.com/sharer/sharer.php?u=https://demo.flatlogic.com/sing-app/html-bs4/","target":"_blank"}},[_c('i',{staticClass:"fa fa-facebook pl-1"})])])])
}]

export { render, staticRenderFns }